import React from "react";
import lazy from "@loadable/component";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { FaWhatsapp } from "react-icons/fa";
// import App from "./App";

const SectionOne = lazy(() => import("../components/section-book/secOne"));
const SectionTwo = lazy(() => import("../components/section-book/secTwo"));
const SectionTheree = lazy(() =>
  import("../components/section-book/secTheree")
);
const SectionFour = lazy(() => import("../components/section-book/secFour"));
const SectionFive = lazy(() => import("../components/section-book/secFive"));
const SectionSix = lazy(() => import("../components/section-book/secSix"));
const SectionSeven = lazy(() => import("../components/section-book/secSeven"));
// const SectionEight = lazy(() => import("../components/section-book/secEight"));
const SectionFaq = lazy(() => import("../components/section-book/secNine"));

class IndexPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout pageInfo={{ pageName: "index" }}>
          <SEO
            title="Home"
            keywords={[`Buat Dia Terus Kembali`, `Lovecoach BDTK`, `BDTK`]}
          />
          {/* <App /> */}
          <SectionOne></SectionOne>
          <SectionTwo></SectionTwo>
          <SectionTheree></SectionTheree>
          <SectionFour></SectionFour>
          <SectionFive></SectionFive>
          <SectionSix></SectionSix>
          <SectionSeven></SectionSeven>
          {/* <SectionEight></SectionEight> */}
          <SectionFaq></SectionFaq>
          <a
            href="https://wa.link/k51rj6"
            target="_blank"
            without
            rel="noreferrer"
            className="float"
          >
            <div className="my-float">
              <FaWhatsapp /> &nbsp; Butuh Bantuan?
            </div>
          </a>
        </Layout>
      </React.Fragment>
    );
  }
}

export default IndexPage;
